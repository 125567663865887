import React from 'react';
import styled from 'styled-components';
import { Button } from 'components/button';
import { LinkButton } from 'components/link-button';
import { NonStretchedImage } from 'components/non-stretched-image';
import { EXTERNAL_LINKS } from 'constants/externalLinks';
import { graphql, useStaticQuery } from 'gatsby';
import MEDIA from 'styles/media';
import { gtagEvent } from 'utils/utils';

import { Banner } from './Banner';

const SBannersSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 8rem 0 0;
  ${MEDIA.max1600`
    flex-direction: column;
    align-items: center;
  `}
`;

const SImage = styled(NonStretchedImage)`
  max-height: 19.6875rem;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 208px;
  ${({ imageLeft }) => (imageLeft ? 'left: 1rem' : 'right: 1rem')};
  ${MEDIA.max420`
    display: none;
  `}
`;

const SBannerTitle = styled.h3`
  font-size: 2rem;
  font-family: 'Abhaya Libre', serif;
  ${MEDIA.max420`
    margin-bottom: 2rem;
    text-align: center;
  `}
`;

const SWrapper = styled.div`
  width: 55%;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 1rem;
  ${MEDIA.max420`
    width: 100%;
    align-items: center;
  `}
`;

export const BannersSection = () => {
  const images = useStaticQuery(graphql`
    query {
      anna: file(relativePath: { eq: "Anna-baner.png" }) {
        childImageSharp {
          gatsbyImageData(width: 208, quality: 100, formats: [WEBP])
        }
      }
      offer: file(relativePath: { eq: "wizual-oferta.png" }) {
        childImageSharp {
          gatsbyImageData(width: 260, quality: 100, formats: [WEBP])
        }
      }
    }
  `);

  return (
    <SBannersSection>
      <Banner left>
        <SWrapper>
          <SBannerTitle>Umów się na wizytę już dziś!</SBannerTitle>
          <Button
            target="_blank"
            href={EXTERNAL_LINKS.BOOKSY_BOOKING_APP}
            text="Zarezerwuj wizytę"
            full
            onClick={gtagEvent}
          />
        </SWrapper>
        <SImage
          childImageSharp={images.anna.childImageSharp}
          alt="anna sadowska lekarz medycyny estetycznej warszawa wola ideale medycyna estetyczna"
          imageLeft
        />
      </Banner>
      <Banner>
        <SWrapper>
          <SBannerTitle>Zapoznaj się z naszą pełną ofertą</SBannerTitle>
          <LinkButton to="/cennik" text="zobacz pełną ofertę" transparent />
        </SWrapper>
        <SImage
          childImageSharp={images.offer.childImageSharp}
          alt="oferta zabiegów ideale medycyna estetyczna"
        />
      </Banner>
    </SBannersSection>
  );
};
