import React from 'react';
import styled from 'styled-components';
import { bool, node } from 'prop-types';
import { COLORS } from 'styles/constants/colors';
import MEDIA from 'styles/media';

const SBanner = styled.div`
  min-width: 548px;
  max-width: 610px;
  width: 48%;
  height: 260px;
  background-color: ${COLORS.LIGHT_GREY};
  position: relative;
  padding: 2.5rem 3.125rem;
  display: flex;
  margin-bottom: 4rem;
  justify-content: ${({ left }) => (left ? 'flex-end' : 'flex-start')};
  ${MEDIA.max1600`
    width: 100%;
    min-width: unset;
    margin-bottom: ${({ left }) => (left ? '4rem' : '0')};
  `}
  ${MEDIA.max420`
    padding: 2rem;
    height: auto;
  `}
`;

export const Banner = ({ children, left }) => {
  return <SBanner left={left}>{children}</SBanner>;
};

Banner.propTypes = {
  children: node.isRequired,
  left: bool,
};

Banner.defaultProps = {
  left: false,
};
